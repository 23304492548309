@import "/css/supreme.css";

:root {
  --color-bg: #d3d3d3;
  --color-text: #000000;

  --color-prompt-text: #181818;
  --color-prompt-bg: #ffffe1;
  --color-prompt-button-text: #000080;

  --color-button-bg: #000080;
  --color-button-text: #ffffff;
  --color-button-border-color: #adadad;
  --color-button-bg-2: #adadad;
  --color-button-inactive-shadow: drop-shadow(0px 1px 0px #e0e0e0);
  --chpy-button-hover: linear-gradient(0deg, #fff3, #fff3), navy;

  --footer-height: 50px;

  --section-bg: #fcfcd1;
  --section-title: #000080;
  --section-label: #000;
}

.theme-dark {
  --color-bg: #1e1e1e;
  --color-text: #ffffff;

  --color-prompt-text: #181818;
  --color-prompt-bg: #e1e1e1;
  --color-prompt-button-text: #232323;

  --color-button-bg: #ffffff;
  --color-button-text: #232323;
  --color-button-border-color: #7d7d7d;
  --color-button-inactive-shadow: drop-shadow(0px 1px 0px #2c2c2c);
  --chpy-button-hover: linear-gradient(0deg, #18181833, #18181833), #e1e1e1;

  --section-bg: #313131;
  --section-title: #e1e1e1;
  --section-label: #adadad;
}

.theme-light {
  --color-bg: #d9d9d9;
  --color-text: #434343;

  --color-prompt-text: #efefef;
  --color-prompt-bg: #434343;
  --color-prompt-button-text: #ffffff;

  --color-button-bg: #434343;
  --color-button-text: #ffffff;
  --color-button-border-color: #c6c6c6;
  --color-button-inactive-shadow: drop-shadow(0px 1px 0px #f0f0f0);
  --chpy-button-hover: linear-gradient(0deg, #fff3, #fff3), #434343;

  --section-bg: #efefef;
  --section-title: #434343;
  --section-label: #000;
}

.theme-glossy {
  --color-bg: #f1f5f9;
  --color-text: #4e5877;

  --color-prompt-text: #efefef;
  --color-prompt-bg: linear-gradient(147.17deg, #73abff -4.72%, #0e6eff 116.58%),
    #f1f5f9;
  --color-prompt-bg-hover: #0e6eff;
  --color-prompt-button-text: #ffffff;

  --color-button-bg: linear-gradient(147.17deg, #73abff -4.72%, #0e6eff 116.58%),
    #f1f5f9;
  --color-button-text: #ffffff;
  --color-button-border-color: rgba(255, 255, 255, 0.15);
  --color-button-inactive-shadow: drop-shadow(0px 1px 0px #f0f0f0);
  --chpy-button-hover: linear-gradient(0deg, #0003, #0003),
    linear-gradient(147.17deg, #73abff -4.72%, #0e6eff 116.58%), #f1f5f9;

  --section-bg: #fff;
  --section-title: #237bff;
  --section-label: #000;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: var(--color-bg);
  color: var(--color-text);
  font-family: "Supreme", Helvetica, sans-serif !important;
  padding: 0;
  /* display: grid; */
  /* grid-template-rows: 1fr var(--footer-height); */
  /* grid-template-columns: 1fr; */
  /* flex-direction: column; */
}

a {
  color: inherit;
  text-decoration: none;
}

/* HERO SECTION */
.hero-wrapper {
  height: 1000px;
  margin-top: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clippybg {
  background-image: url("/clippybg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  height: 120vh;
  width: 100%;
  margin-top: 0;
}

.chippy-background--fixed {
  padding-top: 70vh !important;
  margin-bottom: 100vh !important;
}

.main-container {
  min-height: calc(100vh - var(--footer-height) - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 0 auto;
  margin-top: -100px;
  padding: 0;
}

.clip-folder {
  height: 80px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  margin-top: 40px;
}

.section-wrapper {
  top: -50px;
  overflow: hidden;
  background-color: var(--section-bg);
  border-radius: 10px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: relative;
  text-align: center;
  padding: 50px;
  z-index: 2;
}

.section-wrapper h2 {
  color: var(--section-title);
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  letter-spacing: -0.48px;
  line-height: normal;
}

.origin-story h2 {
  font-size: 24px;
}

.origin-story .heading {
  font-size: 40px;
}

.section-wrapper p {
  color: var(--section-label);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 27.2px;
}

.section-wrapper li {
  color: var(--section-label);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 27.2px;
}

.privacy-image {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
}

.privacy-container {
  margin: 15vw auto;
}

.privacy-wrapper > * {
  margin-top: 1rem;
}

/* INFO  */

.info-label {
  margin: auto;
}

.image {
  height: 200px;
  width: 100%;
  margin: auto;
}

.image .chippy-question {
  height: 200px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.info-image {
  height: 200px;
  margin: auto;
  width: 100%;
}

.info-image .screenshot {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.faq {
  text-align: left;
}

.faq img {
  display: block;
  margin: auto;
}

.faq div {
  margin-top: 1.7rem;
}

.faq ul {
  list-style: decimal;
  list-style-position: inside;
  margin-top: 20px;
}

.download {
  color: var(--color-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center;
  padding-top: 20px;
  z-index: 2;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
}

.download a:hover {
  opacity: 0.7;
}

.download-chippy {
  letter-spacing: 0.18px;
}

.download-chippy.button {
  font-size: 14px;
  padding: 14px 15px;
}

.info-text-container p {
  max-width: 400px;
}

/* .main-container a:not(.button) {
color: var(--color-text);
} */

/* PROMPT BOX */
.chippy-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: fixed;
  top: 20vh;
  left: 45%;
}

.prompt-box {
  background: var(--color-prompt-bg);
  color: var(--color-prompt-text);
  padding: 16px;
  width: 200px;
  border-radius: 16px;
  filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.1));
  position: relative;
  margin-bottom: 30px;
  letter-spacing: -0.02em;
}
/* seo purposes */
.main-header {
  display: inherit;
  font-weight: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-inline-start: inherit;
  margin-inline-end: inherit;
}

.prompt-box p, .main-header {
  margin: 0;
  margin-bottom: 18px;
  font-size: 15px;
  line-height: 20px;
}


.prompt-box a {
  display: block;
  border: 1px solid transparent;
  text-transform: uppercase;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 11px;
  padding: 9px 12px;
  padding-left: 30px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  letter-spacing: 0.02em;
  position: relative;
  color: var(--color-prompt-bg-hover, var(--color-prompt-bg));
  background: var(--color-prompt-button-text);
}

.prompt-box a:hover {
  background: var(--color-prompt-bg-hover, var(--color-prompt-bg));
  color: var(--color-prompt-button-text);
  border-color: var(--color-prompt-button-text);
}

.prompt-box a::before {
  display: none; /* Remove this line if we want the ::before dot again */
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--color-prompt-bg-hover, var(--color-prompt-bg));
  position: absolute;
  left: 8px;
  top: 11px;
}

.prompt-box a:hover::before {
  background: var(--color-prompt-button-text);
}

.message-arrow {
  color: var(--color-prompt-bg);
  bottom: -10px;
  left: 40%;
  position: absolute;
}

.promptImage-wrapper .prompt-image {
  width: 200px;
}

section {
  margin-top: 30px;
}

.origin-story {
  margin-bottom: 150px;
}

.list-styling {
  margin-left: 15px;
}

.link {
  text-decoration: underline;
  color: var(--color-text);
  font-weight: bold;
}

.link:hover {
  opacity: 0.5;
}

/* VIDEO PLAYER */
.video-player {
  max-width: 900px;
  width: 100%;
  object-fit: cover;
}

/* FOOTER */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--footer-height);
  margin-top: 30px;
  padding: 10px;
  z-index: 1;
  position: relative;
}

.footer-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  list-style: none !important;
}

.footer-links a {
  text-decoration: none;
  color: var(--color-text);
  font-weight: bold;
  font-size: 13px;
}

.footer-links a:hover {
  opacity: 0.5;
}

.themes {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-text);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  line-height: 12px;
  /* position: relative; */
  bottom: 30px;
  right: 16px;
}

.themes p {
  font-size: 10px;
  font-weight: 800;
}

.themes .button-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.flex {
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-areas: "top" "bottom";
  margin: 40px auto;
  gap: 10px;
}

/* BUTTON */
.button {
  border-radius: 16px;
  background: transparent;
  color: var(--color-button-bg-2, var(--color-button-border-color));
  opacity: 0.5;
  font-weight: bold;
  border: 1px solid var(--color-button-bg-2, var(--color-button-border-color));
  text-transform: uppercase;
  font-size: 9px;
  line-height: 12px;
  padding: 10px;
  cursor: pointer;
  filter: var(--color-button-inactive-shadow);
}

.theme-window98 .button--inactive {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  opacity: 1;
  filter: none;
  border-color: transparent;
}

.button:hover {
  background: var(--color-button-bg);
  color: var(--color-button-text);
  border-color: var(--color-button-bg);
  opacity: 1;
}

.button--active {
  background: var(--color-button-bg);
  color: var(--color-button-text);
  border-color: var(--color-button-bg);
  filter: none;
  opacity: 1;
}

/* .theme-window98 .button--inactive:hover {
  background: rgba(0, 0, 0, 0.2);
} */

/* NAVBAR COMPONENET */
.navbar {
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  z-index: 2;
  position: relative;
}

.navbar-nav {
  display: flex;
  list-style: none;
  gap: 14px;
}

.hover-button:hover {
  opacity: 0.7;
}

/* ONBOARDING STYLES */
.onboarding-here {
  right: 40px;
  bottom: 100px;
  position: absolute;
}

.onboarding-container {
  margin-top: 0;
}

/* RESPONSIVENESS */

@media (min-width: 768px) {
  .desktop-only {
    display: inherit;
  }

  .faq {
    width: 650px;
    margin: auto;
    margin-top: 10rem;
  }

  .mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-wrapper {
    height: 1000px;
  }

  .position-bottom {
    grid-area: bottom;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .main-container {
    min-height: calc(100vh - ((var(--footer-height) * 2) + 40px));
    /* 40px is the margin added by the header and footer */
    max-width: 95%;
    flex-direction: column;
  }

  .header-nav {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
    height: var(--footer-height);
    padding-top: 15px;
  }

  .footer {
    justify-content: center;
    flex-direction: column;
  }

  .privacy-footer {
    margin-top: 40px;
  }

  .footer-warning {
    color: var(--color-text);
    opacity: 0.3;
    letter-spacing: -0.02em;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
  }

  body {
    grid-template-rows: var(--footer-height) 1fr auto;
  }

  .video-container {
    width: 95%;
    margin: 80px 0;
  }

  section iframe {
    aspect-ratio: 16 / 9;
    height: 100%;
    width: 100%;
  }

  .navbar {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
  }

  .chippy-prompt {
    top: 12rem;
    left: 30%;
  }

  .chippy-background--fixed {
    padding-top: 45rem !important;
  }
}

@media (min-width: 900px) {
  .flex {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: none;
    text-align: left;
    margin: 10rem 60px;
    gap: 40px;
  }

  .info-label {
    width: 500px;
    margin: auto;
  }

  .section-wrapper {
    top: -15px;
  }

  .image {
    height: 100%;
    width: 840px;
    margin: auto;
  }

  .image .chippy-question {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .info-image {
    height: 100%;
    width: 600px;
    margin: auto;
  }

  .info-image .screenshot {
    height: auto;
    object-fit: contain;
    width: 600px;
  }
}

@media (min-width: 1024px) {
  .main-container,
  .main-container.privacy-container {
    max-width: 1024px;
  }

  .section-wrapper {
    top: -10px;
  }
}

@media (min-width: 1279px) {
  .main-container {
    max-width: 1279px;
  }
}

.centered-content {
  text-align: center;
}

h3 {
  margin-bottom: 0.5em;
}
